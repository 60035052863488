










































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getCurrentTime } from "@/utils/base";
import {
  addRecords,
  editRecords,
  getRecords,
  getInspect,
  getConsultationHistory,
  GetJianyanZhibiao,
} from "@/request/custom";
import { UploadFile } from "@/components/template-transfer-pc/template-transfer/request";
import DsCarousel from "@/components/ds-carousel/ds-carousel.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
import Qushi from "../../components/qushi/qushi.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    DsCarousel,
    LeaveConfirm,
    Qushi,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private routerPath: any; // 页面要跳转的路由
  @Prop()
  private leaveType: any; // 离开，下一步，切换
  @Prop()
  private leavePath: any;
  @Prop()
  private changeData: any; // 评估未保存，确认保存会用到
  @Prop()
  private pageSource: any;
  @Prop()
  private pingguData: any;
  @Prop()
  private assess_id: any;
  @Prop()
  private noSaveText: any;
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowSaveConfirm = this.ifShow;
  }
  @Prop()
  private id: any;
  @Watch("id", { immediate: true })
  private idChange() {
    if (this.pageSource !== "评估") {
      this.resetData();
    }
  }
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    if (
      this.refreshDataTime &&
      this.pageSource == "评估" &&
      this.pingguData["类别"] == "checkout"
    ) {
      this.resetData();
    }
  }
  private ifShowSaveConfirm: any = false;
  private detail: any = {};
  private queryData: any = {};
  private IfShowAdd: any = false;
  private addData: any = {};
  private options: any = [];
  private ifShowQushi: any = false;
  private qushiData: any = {};
  private editZhibiaoType: any = "添加";
  private editIndex: any = -1;
  private ifShowTongbu: any = false;
  private selectVal: any = {};
  private tongbuList: any = [];
  private disabledDate: any = this.endDate();
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private inputChange(item: any, index: any) {
    const d = JSON.parse(JSON.stringify(item));
    this.$set(this.detail.indexes, index, d);
    this.$forceUpdate();
  }
  private delImg(index: any) {
    this.$confirm("确定要删除吗？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        this.detail.pic_list.splice(index, 1);
      })
      .catch((e) => {
        console.log("取消了");
      });
  }
  private preview(item: any) {
    window.open(item.url);
  }
  private goHome() {
    this.$router.push("/main/workstation/patient-file");
  }
  private openTongbu() {
    this.selectVal = {};
    this.getHistoryData();
    this.ifShowTongbu = true;
  }
  private tongbu() {
    if (!this.selectVal.inspect_id) {
      this.$message.warning("请选择需要同步的记录");
      return;
    }
    this.detail.indexes = this.selectVal.indexes;
    this.detail.content = this.selectVal.content;
    this.detail.ocr_origin = this.selectVal.ocr_origin;
    this.detail.pic_list = this.selectVal.pic_list;
    this.ifShowTongbu = false;
  }
  private getHistoryData() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
        type: this.pingguData.inspect_name,
      },
    };
    getConsultationHistory(this, params).then((res: any) => {
      this.tongbuList = res;
    });
  }
  private getResult(item: any) {
    if (!item.result) {
      return "";
    }
    const val = Number(item.result);
    const min = Number(item.interval.split("~")[0]);
    const max = Number(item.interval.split("~")[1]);
    let result: any = "正常";
    if (val < min) {
      result = "偏低";
    } else if (val > max) {
      result = "偏高";
    }
    return result;
  }
  private openAdd() {
    this.IfShowAdd = true;
    this.addData = {
      name: {},
    };
    this.editZhibiaoType = "添加";
  }
  private addSure() {
    if (!this.addData.name.index_name) {
      this.$message.warning("请选择指标名称");
      return;
    }
    if (!this.addData.result) {
      this.$message.warning("请输入结果");
      return;
    }
    const obj = JSON.parse(JSON.stringify(this.addData.name));
    obj.result = this.addData.result;
    if (this.editZhibiaoType == "添加") {
      this.detail.indexes.push(obj);
    } else if (this.editZhibiaoType == "编辑") {
      this.detail.indexes[this.editIndex] = obj;
      this.forceUpdate();
    }
    this.IfShowAdd = false;
  }
  private focus(e: any) {
    if (e) {
      this.remoteMethod("");
    }
  }
  private remoteMethod(e: any) {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          inspect_id: this.detail.temp_id,
          index_name: e,
        },
      };
      getInspect(this, params).then((res: any) => {
        this.options = res;
        resolve(res);
      });
    });
  }
  private openQushi(item: any) {
    this.qushiData = item;
    this.ifShowQushi = true;
  }
  private closeQushi() {
    this.ifShowQushi = false;
  }
  private editZhibiao(item: any, index: any) {
    this.editZhibiaoType = "编辑";
    this.editIndex = index;
    this.remoteMethod("").then(() => {
      const obj = JSON.parse(JSON.stringify(item));
      delete obj.result;
      this.addData = {
        name: obj,
        result: item.result,
      };
      this.IfShowAdd = true;
    });
  }
  private delZhibiao(item: any, index: any) {
    this.$confirm("您确定要删除吗。", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        this.detail.indexes.splice(index, 1);
      })
      .catch(() => {});
  }
  private cancel() {
    this.$emit("cancel");
  }

  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private del(i: any) {
    this.detail.pic_list.splice(i, 1);
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  // 保存确认弹框保存
  private changeSave() {
    this.cancel();
    this.save().then(() => {
      if (this.leaveType == "切换") {
        this.$emit("changeTab", this.changeData);
      } else if (this.leaveType == "下一步") {
        this.$emit("goReport");
      } else {
        if (this.routerPath == "/login") {
          this.$store.commit("updateToken", "");
          this.$store.commit("updateUser", null);
          this.$router.replace("/login");
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          this.$router.push(this.routerPath);
        }
      }
    });
  }
  // 保存确认弹框不保存,切换
  private changeConfirmed() {
    this.cancel();
    this.$store.commit("updateIfCompose", false);
    if (this.leaveType == "切换") {
      this.$emit("changeTab", this.changeData);
    } else if (this.leaveType == "下一步") {
      this.$emit("goReport");
    } else {
      if (this.routerPath == "/login") {
        this.$store.commit("updateToken", "");
        this.$store.commit("updateUser", null);
        this.$router.replace("/login");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$router.push(this.routerPath);
      }
    }
  }
  /**
   * @description 列表上传文档
   */
  private uploadFile(d: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    UploadFile(this, form)
      .then((data: any) => {
        loading.close();
        const arr: any = d.file.name.split(".");
        const nameArr = arr.slice(0, -1);
        const obj = {
          name: nameArr.join("."),
          type: arr[arr.length - 1],
          url: data.full_file_url,
        };
        this.detail.pic_list.push(obj);
      })
      .catch(() => {
        loading.close();
      });
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        inspect_type: "checkout",
        patient_id: this.patient.patient_id,
        inspect_id: this.detail.inspect_id,
      },
    };
    getRecords(this, params)
      .then((res: any) => {
        loading.close();
        this.detail = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private save() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      if (this.detail.inspect_id) {
        const params: any = {
          assess_id: this.assess_id ? this.assess_id : this.detail.assess_id,
          inspect_id: this.detail.inspect_id,
          // patient_id: this.patient.patient_id,
          inspect_time: this.detail.inspect_time,
          // inspect_type: "checkout",
          pic_list: this.detail.pic_list,
          indexes: this.detail.indexes,
        };
        editRecords(this, params)
          .then((res: any) => {
            loading.close();
            this.$store.commit("updateIfCompose", false);
            this.$message.success("保存成功");
            if (this.pageSource == "评估" && this.leaveType != "切换") {
              this.$emit("refresh");
            } else if (this.pageSource != "评估") {
              this.detail = res;
              this.getDetail();
            }
            resolve(true);
          })
          .catch(() => {
            loading.close();
          });
      } else {
        const params: any = {
          assess_id: this.assess_id ? this.assess_id : this.detail.assess_id,
          temp_id: this.detail.temp_id,
          patient_id: this.patient.patient_id,
          inspect_time: this.detail.inspect_time,
          inspect_type: "checkout",
          pic_list: this.detail.pic_list,
          indexes: this.detail.indexes,
        };
        addRecords(this, params)
          .then((res: any) => {
            loading.close();
            this.$store.commit("updateIfCompose", false);
            this.$message.success("保存成功");
            if (this.pageSource == "评估" && this.leaveType != "切换") {
              this.$emit("refresh");
            } else if (this.pageSource != "评估") {
              this.detail = res;
              this.getDetail();
            }
            resolve(true);
          })
          .catch(() => {
            loading.close();
          });
      }
    });
  }
  private resetData() {
    if (this.id) {
      this.detail.inspect_id = this.id;
      this.getDetail();
    } else {
      if (!this.queryData.temp_id && !this.pingguData.temp_id) {
        return;
      }
      // 获取默认指标项
      const params: any = {
        params: {
          temp_id: this.queryData.temp_id || this.pingguData.temp_id,
        },
      };
      GetJianyanZhibiao(this, params).then((res: any) => {
        this.detail = {
          pic_list: [],
          temp_id: this.queryData.temp_id
            ? this.queryData.temp_id
            : this.pingguData.temp_id,
          inspect_time: getCurrentTime("yyyy-MM-dd HH:mm:ss"),
          inspect_name: this.queryData.inspect_name
            ? this.queryData.inspect_name
            : this.pingguData.inspect_name,
          indexes: res["检验指标"],
          类别: "checkout",
        };
        this.forceUpdate();
      });
    }
  }
  private mounted() {
    if (this.$route.query.data) {
      this.queryData = JSON.parse(this.$route.query.data as any);
    }
    this.resetData();
  }
}
