
































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getCurrentTime } from "@/utils/base";
import { UploadFile } from "@/components/template-transfer-pc/template-transfer/request";
import {
  editConsultation,
  getConsultation,
  getConsultationHistory,
} from "@/request/custom";
import DsCarousel from "@/components/ds-carousel/ds-carousel.vue";
import DsEditor from "@/components/ds-editor/ds-editor.vue";
import Empty from "@/components/empty.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
@Component({
  components: {
    DsCarousel,
    DsEditor,
    Empty,
    LeaveConfirm,
  },
})
export default class Name extends Vue {
  @Prop()
  private routerPath: any; // 页面要跳转的路由
  @Prop()
  private leaveType: any; // 离开，下一步，切换
  @Prop()
  private leavePath: any; // 离开的路由
  @Prop()
  private changeData: any; // 评估未保存，确认保存会用到
  @Prop()
  private pageSource: any;
  @Prop()
  private pingguData: any;
  @Prop()
  private assess_id: any;
  @Prop()
  private noSaveText: any;
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowSaveConfirm = this.ifShow;
  }
  @Prop()
  private id: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    if (
      this.refreshDataTime &&
      this.pageSource == "评估" &&
      this.pingguData["类别"] == "住院记录"
    ) {
      this.resetData();
    }
  }
  private ifShowSaveConfirm: any = false;
  // private zhuyuanType: any = ["病案首页", "入院记录", "首次病程录", "出院记录"];
  private zhuyuanType: any = ["出院记录"];
  private zhuyuanTab: any = "出院记录";
  private detail: any = {};
  private ifShowTongbu: any = false;
  private selectVal: any = {};
  private tongbuList: any = [];
  private disabledDate: any = this.endDate();
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private delImg(index: any) {
    this.$confirm("确定要删除吗？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        this.detail[this.zhuyuanTab].pic_list.splice(index, 1);
      })
      .catch((e) => {
        console.log("取消了");
      });
  }
  private preview(item: any) {
    window.open(item.url);
  }
  private goHome() {
    this.$router.push("/main/workstation/patient-file");
  }
  private changeTab(item: any) {
    this.zhuyuanTab = item;
    if (this.$refs.myEditor) {
      (this.$refs.myEditor as any).refresh();
    }
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private openTongbu() {
    this.selectVal = {};
    this.getHistoryData();
    this.ifShowTongbu = true;
  }
  private tongbu() {
    if (!this.selectVal.consultation_id) {
      this.$message.warning("请选择需要同步的记录");
      return;
    }
    this.detail["病案首页"].pic_list = this.selectVal["病案首页"].pic_list;
    this.detail["病案首页"].content = this.selectVal["病案首页"].content;
    this.detail["病案首页"].ocr_origin = this.selectVal["病案首页"].ocr_origin;

    this.detail["入院记录"].pic_list = this.selectVal["入院记录"].pic_list;
    this.detail["入院记录"].content = this.selectVal["入院记录"].content;
    this.detail["入院记录"].ocr_origin = this.selectVal["入院记录"].ocr_origin;

    this.detail["首次病程录"].pic_list = this.selectVal["首次病程录"].pic_list;
    this.detail["首次病程录"].content = this.selectVal["首次病程录"].content;
    this.detail["首次病程录"].ocr_origin =
      this.selectVal["首次病程录"].ocr_origin;

    this.detail["出院记录"].pic_list = this.selectVal["出院记录"].pic_list;
    this.detail["出院记录"].content = this.selectVal["出院记录"].content;
    this.detail["出院记录"].ocr_origin = this.selectVal["出院记录"].ocr_origin;

    this.ifShowTongbu = false;
  }
  private getHistoryData() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
        type: this.pingguData["类别"],
      },
    };
    getConsultationHistory(this, params).then((res: any) => {
      this.tongbuList = res;
    });
  }
  private cancel() {
    this.$emit("cancel");
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private del(i: any) {
    this.detail[this.zhuyuanTab].pic_list.splice(i, 1);
  }
  private editorChange(e: any) {
    this.detail[this.zhuyuanTab].content = e;
  }
  private uploadFile(d: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    UploadFile(this, form)
      .then((data: any) => {
        loading.close();
        const arr: any = d.file.name.split(".");
        const nameArr = arr.slice(0, -1);
        const obj = {
          name: nameArr.join("."),
          type: arr[arr.length - 1],
          url: data.full_file_url,
        };
        this.detail[this.zhuyuanTab].pic_list.push(obj);
      })
      .catch(() => {
        loading.close();
      });
  }
  private save() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        assess_id: this.assess_id ? this.assess_id : this.detail.assess_id,
        consultation_id: this.detail.consultation_id,
        patient_id: this.patient.patient_id,
        类别: "住院记录",
      };
      params["就诊时间"] = this.detail["出院记录"]["就诊时间"];
      params["病案首页"] = this.detail["病案首页"];
      params["入院记录"] = this.detail["入院记录"];
      params["首次病程录"] = this.detail["首次病程录"];
      params["出院记录"] = this.detail["出院记录"];
      editConsultation(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("保存成功");
          this.$store.commit("updateIfCompose", false);
          this.detail = res;
          if (this.pageSource == "评估") {
            this.$emit("refresh", res);
          } else {
            this.detail = res;
            this.getDetail();
          }
        })
        .catch(() => {
          loading.close();
        });
      resolve(true);
    });
  }
  private getDetail() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        consultation_id: this.detail.consultation_id,
        patient_id: this.patient.patient_id,
      },
    };
    getConsultation(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.detail = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private resetData() {
    if (this.id) {
      this.detail.consultation_id = this.id;
      this.getDetail();
    } else {
      let t: any = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.detail = {
        就诊时间: t,
        类别: "住院记录",
        病案首页: {
          类别: "病案首页",
          就诊时间: t,
          pic_list: [],
        },
        入院记录: {
          类别: "入院记录",
          就诊时间: t,
          pic_list: [],
        },
        首次病程录: {
          类别: "首次病程录",
          就诊时间: t,
          pic_list: [],
        },
        出院记录: {
          类别: "出院记录",
          就诊时间: t,
          pic_list: [],
        },
      };
      this.forceUpdate();
    }
  }
  // 保存确认弹框保存
  private changeSave() {
    this.cancel();
    this.save().then(() => {
      if (this.leaveType == "切换") {
        this.$emit("changeTab", this.changeData);
      } else if (this.leaveType == "下一步") {
        this.$emit("goReport");
      } else {
        if (this.routerPath == "/login") {
          this.$store.commit("updateToken", "");
          this.$store.commit("updateUser", null);
          this.$router.replace("/login");
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          this.$router.push(this.routerPath);
        }
      }
    });
  }
  // 保存确认弹框不保存,切换
  private changeConfirmed() {
    this.cancel();
    this.$store.commit("updateIfCompose", false);
    if (this.leaveType == "切换") {
      this.$emit("changeTab", this.changeData);
    } else if (this.leaveType == "下一步") {
      this.$emit("goReport");
    } else {
      if (this.routerPath == "/login") {
        this.$store.commit("updateToken", "");
        this.$store.commit("updateUser", null);
        this.$router.replace("/login");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$router.push(this.routerPath);
      }
    }
  }
  private mounted() {
    this.resetData();
  }
}
