























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class LeaveConfirmDialog extends Vue {
  @Prop()
  private title: any;
  @Prop()
  private help: any;
  @Prop()
  private visible: boolean | undefined;
  @Prop()
  private noSaveText: any;
  private visibleChange() {
    this.$emit("update:visible", this.visible);
  }
  private get ifVisible() {
    return this.visible ? this.visible : false;
  }
  private set ifVisible(val) {
    this.$emit("update:visible", val);
  }
  /**
   * @description 取消
   */
  private cancel() {
    this.$emit("cancel");
  }
  /**
   * @description 保存
   */
  private save() {
    this.$emit("save");
  }
  /**
   * @description 不保存，离开
   */
  private leave() {
    this.$emit("leave");
  }
}
