





























































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getCurrentTime } from "@/utils/base";
import {
  IfEmpty,
  hideContent,
} from "@/components/template-transfer-pc/template-transfer/common";
import {
  getTemplate,
  saveTemplate,
  getContent,
  getContentHistory,
} from "@/request/custom";
import { hasDeviceData, TongbuDeviceData } from "@/request/doctor";
import TemplateTransfer from "@/components/template-transfer-pc/template-transfer/template-transfer.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
@Component({
  components: {
    TemplateTransfer,
    LeaveConfirm,
  },
})
export default class Name extends Vue {
  @Prop()
  private pageType: any; // 查看页面不显示编辑按钮，返回是返回上一页
  @Prop()
  private routerPath: any; // 页面要跳转的路由
  @Prop()
  private leaveType: any; // 离开，下一步，切换
  @Prop()
  private leavePath: any; // 离开的路由
  @Prop()
  private pingguData: any; // 评估的心脏无忧自动填充会用到
  @Prop()
  private pageSource: any;
  @Prop()
  private changeData: any; // 评估未保存，确认保存会用到
  @Prop()
  private template_id: any;
  @Watch("template_id", { immediate: true })
  private Template_idChange() {
    this.data = {};
  }
  @Prop()
  private followup_id: any;
  @Prop()
  private content_id: any;
  @Watch("content_id", { immediate: true })
  private Content_idChange() {
    this.data = {};
  }
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    if (
      this.refreshDataTime &&
      this.pageSource == "评估" &&
      this.pingguData["类别"] != "门诊记录" &&
      this.pingguData["类别"] != "住院记录" &&
      this.pingguData["类别"] != "checkout" &&
      this.pingguData["类别"] != "checkup"
    ) {
      this.data = {};
      this.getData();
    }
    if (this.pageSource !== "评估") {
      this.getData();
    }
  }
  @Prop()
  private assess_id: any;
  @Prop()
  private noSaveText: any;
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowSaveConfirm = this.ifShow;
  }
  private ifShowSaveConfirm: any = false;
  private data: any = {};
  private selectVal: any = {};
  private redIndex: any = -1; // 需要标红提示未填的index
  private ifShowTongbu: any = false;
  private ifShowTongbuCeping: any = false;
  private hasYitiData: any = false;
  private tongbucepingList: any = [];
  private disabledDate: any = this.endDate();
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get patientFileFilter() {
    return this.$store.state.patientFileFilter;
  }
  private goHome() {
    if (this.pageType == "read") {
      this.$router.back();
    } else {
      this.$router.push("/main/workstation/patient-file");
    }
  }
  private tongbuCeping() {
    if (!this.selectVal.content_id) {
      this.$message.warning("请选择需要同步的记录");
      return;
    }
    this.data.data = this.selectVal.data;
    this.ifShowTongbuCeping = false;
  }
  private openTongbu() {
    this.checkIfHasDeviceData();
    this.ifShowTongbu = true;
  }
  private openTongbuCeping() {
    this.selectVal = {};
    this.getHistoryData();
    this.ifShowTongbuCeping = true;
  }
  private checkIfHasDeviceData() {
    const params = {
      patient_id: this.patient.patient_id,
      device_type: "k5",
      date: new Date(),
    };
    hasDeviceData(this, params).then((data: any) => {
      this.hasYitiData = data.result;
      if (this.hasYitiData) {
        this.ifShowTongbu = true;
      }
    });
  }
  private tongbuData() {
    const params = {
      patient_id: this.patient.patient_id,
      date: new Date(),
    };
    TongbuDeviceData(this, params).then((d: any) => {
      // 需要同步除了病史资料之外的数据
      const keyArr: any = [
        {
          name: "身高",
          label: "身高",
        },
        {
          name: "体重",
          label: "体重",
        },
        {
          name: "BMI",
          label: "bmi",
        },
        {
          name: "腰围（肚脐以上一公分处）",
          label: "腰围",
        },
        {
          name: "收缩压",
          label: "收缩压",
        },
        {
          name: "舒张压",
          label: "舒张压",
        },
        {
          name: "总胆固醇",
          label: "总胆固醇",
        },
        {
          name: "高密度脂蛋白",
          label: "高密度脂蛋白",
        },
        {
          name: "低密度脂蛋白",
          label: "低密度脂蛋白",
        },
        {
          name: "甘油三酯",
          label: "甘油三酯",
        },
      ];
      keyArr.forEach((element: any) => {
        this.data.data.forEach((ele: any) => {
          if (ele.title == element.name) {
            ele.value = d[element.label];
          }
        });
      });
      this.$message.success("同步成功");
      this.ifShowTongbu = false;
    });
  }
  private getHistoryData() {
    const params: any = {
      params: {
        patient_id: this.patient.patient_id,
        template_id: this.data.template_id,
      },
    };
    getContentHistory(this, params).then((res: any) => {
      this.tongbucepingList = res;
    });
  }
  private cancel() {
    this.$emit("cancel");
  }
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private updateTemplate(e: any) {
    this.data.data[e.index] = e.data;
    //计算BMI，量表顺序只能是身高，体重，BMI
    if (e.data.title === "身高") {
      const shengao = this.data.data[e.index].value / 100;
      const tizhong = this.data.data[e.index + 1].value;
      this.data.data[e.index + 2].value = (
        tizhong /
        (shengao * shengao)
      ).toFixed(2);
    } else if (e.data.title === "体重") {
      const shengao = this.data.data[e.index - 1].value / 100;
      const tizhong = this.data.data[e.index].value;
      this.data.data[e.index + 1].value = (
        tizhong /
        (shengao * shengao)
      ).toFixed(2);
    }
    this.$forceUpdate();
  }
  /**
   * @description 隐藏或者显示某些相关题目
   */
  private changeHide(e: any) {
    const data = this.data.data;
    hideContent(e, data);
  }
  private getData() {
    if (!this.template_id && !this.content_id) {
      return;
    }
    if (this.content_id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        content_id: this.content_id,
      };
      getContent(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.data = res;
        })
        .catch((res: any) => {
          loading.close();
        });
    } else {
      // 评估的心脏无忧列表需要用传入的模板信息（带填充信息）
      if (
        this.pageSource == "评估" &&
        this.pingguData["名称"] &&
        this.pingguData["名称"] == "心脏无忧筛查"
      ) {
        this.data = JSON.parse(JSON.stringify(this.pingguData));
        this.data["采集时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
        this.forceUpdate();
      } else {
        if (!this.template_id) {
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const params: any = {
          template_id: this.template_id,
        };
        getTemplate(this, params, loading).then((res: any) => {
          res.data = res["模板"];
          res["采集时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
          this.data = res;
        });
      }
    }
  }
  private save() {
    return new Promise((resolve, reject) => {
      // 判断必填项是否填完
      for (let i = 0; i < this.data.data.length; i++) {
        const weitian = IfEmpty(this.data.data[i]);
        if (weitian) {
          // 弹提示哪个未填,并用红框框上
          this.$message.warning(this.data.data[i].title + "为必填项！");
          this.redIndex = i;
          // 滚动到没填的题目
          const parent = (document as any).getElementById("liangbiao-detail");
          const child = (document as any).getElementById("liangbiao" + i);
          parent.scrollTop = child.offsetTop - parent.offsetTop;
          return;
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        assess_id: this.assess_id ? this.assess_id : this.data.assess_id,
        patient_id: this.patient.patient_id,
        template_id: this.data.template_id,
        content_id: this.data.content_id,
        need_score: this.data.need_score,
        采集时间: this.data["采集时间"],
        data: this.data.data,
        followup_id: this.followup_id,
      };
      saveTemplate(this, params, loading).then((res: any) => {
        if (!this.data.content_id) {
          // 新增的时候需要删除三级的选中，保证回到上面的一个页面时选中的是第一个，就是新增的这一条
          const d = JSON.parse(JSON.stringify(this.patientFileFilter));
          d.thirdTab = "";
          this.$store.commit("updatePatientFileFilter", d);
        }
        this.$message.success("保存成功！");
        this.redIndex = -1;
        this.$store.commit("updateIfCompose", false);
        if (
          (this.pageSource == "评估" || this.pageSource == "随访") &&
          this.leaveType != "切换"
        ) {
          this.$emit("refresh");
        } else if (this.pageSource == "测评" && !this.content_id) {
          this.$emit("refresh", res);
        } else if (this.pageSource != "评估") {
          this.data = res;
          this.getData();
        }
        resolve(true);
      });
    });
  }
  // 保存确认弹框保存
  private changeSave() {
    this.cancel();
    this.save().then(() => {
      if (this.leaveType == "切换") {
        this.data = {};
        this.$emit("changeTab", this.changeData);
      } else if (this.leaveType == "离开") {
        if (this.routerPath == "/login") {
          this.$store.commit("updateToken", "");
          this.$store.commit("updateUser", null);
          this.$router.replace("/login");
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          this.$router.push(this.routerPath);
        }
      } else if (this.leaveType == "下一步") {
        this.$emit("goReport");
      } else {
        setTimeout(() => {
          this.$router.back();
        }, 100);
      }
    });
  }
  // 保存确认弹框不保存
  private changeConfirmed() {
    this.redIndex = -1;
    this.cancel();
    this.$store.commit("updateIfCompose", false);
    if (this.leaveType == "切换") {
      this.$emit("changeTab", this.changeData);
    } else if (this.leaveType == "下一步") {
      this.$emit("goReport");
    } else {
      if (this.routerPath == "/login") {
        this.$store.commit("updateToken", "");
        this.$store.commit("updateUser", null);
        this.$router.replace("/login");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$router.push(this.routerPath);
      }
    }
  }
}
